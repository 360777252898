@tailwind base;
@tailwind components;
@tailwind utilities;

input:focus {
  outline: none;
}

:root {
  --swiper-pagination-bullet-inactive-color: theme('colors.gray.200');
  --swiper-pagination-color: theme('colors.gray.600');
  --swiper-pagination-bullet-inactive-opacity: 1;
  --swiper-pagination-bullet-horizontal-gap: 5px;
}

.swiper-pagination-bullet {
  transition: width 0.25s ease;
}

.swiper-pagination-bullet-active {
  width: 28px !important;
  border-radius: 4px !important;
}

@layer components {
  .bg-login-light-bubbles-1 {
    background: url('~/src/assets/images/login_bg_light.svg') no-repeat bottom;
    background-size: contain;
  }
  .bg-login-light-bubbles-1:before {
    content: '';
    position: absolute;
    top: -44px;
    left: 56px;
    width: 120px;
    height: 120px;
    backdrop-filter: blur(11px);
  }
  .bg-login-light-bubbles-1:after {
    content: '';
    position: absolute;
    top: 86px;
    right: -44px;
    width: 170px;
    height: 170px;
    backdrop-filter: blur(42px);
  }
  .bg-login-light-bubbles-1:before,
  .bg-login-light-bubbles-1:after,
  .bg-login-light-bubbles-2:before {
    background: linear-gradient(95.58deg, #cee8e7 27.33%, #9cc5db 100%);
    opacity: 0.5;
    box-shadow: -16px -41px 50px rgba(0, 0, 0, 0.15);
    transform: rotate(-180deg);
    border-radius: 100%;
  }
  .bg-login-light-bubbles-2 {
    background: url('~/src/assets/images/mobile_login_bg_light.svg') no-repeat bottom;
    background-size: contain;
  }
  .bg-login-light-bubbles-2:before {
    content: '';
    position: absolute;
    top: -66px;
    left: -71px;
    width: 120px;
    height: 120px;
    backdrop-filter: blur(42px);
  }

  .bg-header {
    background: linear-gradient(
        95deg,
        theme('colors.white') 0%,
        #cee8e7 15.1%,
        #cee8e7 85.42%,
        #9cc5db 100%
      ),
      linear-gradient(95deg, #fff 0%, #cee8e7 30.59%, #9cc5db 100%);
  }
  .bg-hero {
    background:
      url('~/src/assets/images/hero_bg.svg') no-repeat 98% 32px,
      linear-gradient(271.08deg, #235066 0%, theme('colors.gray.600') 100%);
  }
  .bg-card-dark {
    background:
      url('~/src/assets/images/card_bg_shapes_dark.svg') right 40px bottom 0px no-repeat,
      linear-gradient(270deg, #235066 0%, theme('colors.gray.600') 100%);
    border-radius: 18px;
  }
  .bg-card-dark-border {
    background: linear-gradient(to right, #909fa6, #337ea4) 100%;
    padding: 2px;
    border-radius: 20px;
  }
  .bg-card-light {
    background:
      url('~/src/assets/images/card_bg_shapes_light.svg') right 40px bottom 0px no-repeat,
      linear-gradient(270deg, #9cc5db 0%, #cee8e7 100%);
    border-radius: 18px;
  }
  .bg-card-light-border {
    background: linear-gradient(to right, #e8f4f3, #c8dee9) 100%;
    padding: 2px;
    border-radius: 20px;
  }
  .bg-card-plastic {
    background: url('~/src/assets/images/premium-plastic.png') no-repeat;
    background-size: contain;
    background-color: #b6b2ab;
    border-radius: 16px;
  }
  .bg-card-virtual {
    background: url('~/src/assets/images/premium-virtual.png') no-repeat;
    background-size: contain;
    background-color: #d9dada;
    border-radius: 16px;
  }
  .bg-card-szamlazz {
    background: url('~/src/assets/images/premium-szamlazz.png') no-repeat;
    background-size: contain;
    border-radius: 16px;
  }
  .bg-menu-header {
    background: linear-gradient(95.32deg, theme('colors.white') 0%, #cee8e7 51.66%, #9cc5db 100%);
  }
  .bg-menu-footer {
    background: linear-gradient(180deg, rgba(243, 246, 246, 0) 0%, #f3f6f6 18.75%);
  }
  .bg-footer {
    background: linear-gradient(271.08deg, #235066 0%, #607985 100%);
  }
  .invoice-data-card-curved-corner-topleft,
  .invoice-data-card-curved-corner-topright {
    width: 40px;
    height: 40px;
    overflow: hidden;
  }
  .invoice-data-card-curved-corner-topleft:before,
  .invoice-data-card-curved-corner-topright:before {
    content: '';
    display: block;
    width: 200%;
    height: 200%;
    position: absolute;
    border-radius: 34px;
  }
  .invoice-data-card-curved-corner-topleft:before {
    top: 0;
    left: 0;
    box-shadow: -50px -50px 0 0 theme('colors.secondary.300');
  }
  .invoice-data-card-curved-corner-topright:before {
    top: 0;
    right: 0;
    box-shadow: 50px -50px 0 0 theme('colors.secondary.300');
  }
  .partners-favorites-swiper-cards-gradient-edges {
    mask-image: linear-gradient(to right, transparent 0%, black 3% 97%, transparent 100%);
  }
  .my-cards-swiper-cards-gradient-edges {
    mask-image: linear-gradient(to right, transparent 0%, black 5% 96%, transparent 100%);
  }
  .add-new-partner-dropdown-curved-corner {
    width: 100px;
    height: 100px;
    overflow: hidden;
    position: relative;
    margin-top: -75.2px;
    margin-left: 143.8px;
    transform: scale(0.5);
    z-index: -1;
  }
  .add-new-partner-dropdown-curved-corner:before {
    content: '';
    display: block;
    width: 100px;
    height: 100px;
    position: absolute;
    border-radius: 50%;
  }
  .add-new-partner-dropdown-curved-corner:before {
    top: 0;
    left: 0;
    box-shadow: 50px 50px 0 0 white;
  }
  .icon-bg {
    background: linear-gradient(271.08deg, #235066 0%, #607985 100%);
  }
  .binx-partner-gradient {
    background: linear-gradient(270.31deg, #ffffff 0.24%, rgba(255, 255, 255, 0) 99.73%),
      linear-gradient(95.58deg, #cee8e7 27.33%, #9cc5db 100%), #ffffff;
  }
  .header-account-popover-bg {
    background:
      url('~/src/assets/images/header_popover_shape.svg') no-repeat center,
      linear-gradient(270.31deg, #ffffff 0.24%, rgba(255, 255, 255, 0) 99.73%),
      linear-gradient(95.58deg, rgba(206, 232, 231, 0.5) 27.33%, rgba(156, 197, 219, 0.5) 100%);
    background-position: left;
  }
  .card-trx-data-section-bg {
    background:
      url('~/src/assets/images/header_popover_shape.svg') no-repeat,
      linear-gradient(77.47deg, #cfe1e5 9.09%, #ffffff 90.91%);
    background-position: left;
    background-size: auto 100%;
  }
  .main {
    min-height: calc(100vh - 184px);
  }
  .list-vertical-lines:before {
    content: '';
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    height: 100%;
    width: 2px;
    background-color: #4299e1;
  }
  .bg-active-issue {
    background: linear-gradient(90deg, rgba(225, 238, 244, 0) 0%, #cbe1ec 100%),
      linear-gradient(0deg, #f0f6fa, #f0f6fa);
  }
}
