*, :before, :after {
  box-sizing: border-box;
  border: 0 solid #e1e7ea;
}

:before, :after {
  --tw-content: "";
}

html, :host {
  -webkit-text-size-adjust: 100%;
  tab-size: 4;
  font-feature-settings: normal;
  font-variation-settings: normal;
  -webkit-tap-highlight-color: transparent;
  font-family: Montserrat, ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Noto Sans, Ubuntu, Cantarell, Helvetica Neue, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  line-height: 1.5;
}

body {
  line-height: inherit;
  margin: 0;
}

hr {
  height: 0;
  color: inherit;
  border-top-width: 1px;
}

abbr:where([title]) {
  text-decoration: underline dotted;
}

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
}

a {
  color: inherit;
  -webkit-text-decoration: inherit;
  text-decoration: inherit;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp, pre {
  font-feature-settings: normal;
  font-variation-settings: normal;
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

table {
  text-indent: 0;
  border-color: inherit;
  border-collapse: collapse;
}

button, input, optgroup, select, textarea {
  font-feature-settings: inherit;
  font-variation-settings: inherit;
  font-family: inherit;
  font-size: 100%;
  font-weight: inherit;
  line-height: inherit;
  letter-spacing: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
}

button, select {
  text-transform: none;
}

button, input:where([type="button"]), input:where([type="reset"]), input:where([type="submit"]) {
  -webkit-appearance: button;
  background-color: #0000;
  background-image: none;
}

:-moz-focusring {
  outline: auto;
}

:-moz-ui-invalid {
  box-shadow: none;
}

progress {
  vertical-align: baseline;
}

::-webkit-inner-spin-button {
  height: auto;
}

::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

summary {
  display: list-item;
}

blockquote, dl, dd, h1, h2, h3, h4, h5, h6, hr, figure, p, pre {
  margin: 0;
}

fieldset {
  margin: 0;
  padding: 0;
}

legend {
  padding: 0;
}

ol, ul, menu {
  margin: 0;
  padding: 0;
  list-style: none;
}

dialog {
  padding: 0;
}

textarea {
  resize: vertical;
}

input::placeholder, textarea::placeholder {
  opacity: 1;
  color: #97abb4;
}

button, [role="button"] {
  cursor: pointer;
}

:disabled {
  cursor: default;
}

img, svg, video, canvas, audio, iframe, embed, object {
  vertical-align: middle;
  display: block;
}

img, video {
  max-width: 100%;
  height: auto;
}

[hidden] {
  display: none;
}

*, :before, :after, ::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position: ;
  --tw-gradient-via-position: ;
  --tw-gradient-to-position: ;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #3b82f680;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
  --tw-contain-size: ;
  --tw-contain-layout: ;
  --tw-contain-paint: ;
  --tw-contain-style: ;
}

.container {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-left: 0;
  padding-right: 0;
}

@media (width >= 640px) {
  .container {
    max-width: 640px;
  }
}

@media (width >= 768px) {
  .container {
    max-width: 768px;
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

@media (width >= 1024px) {
  .container {
    max-width: 1024px;
  }
}

@media (width >= 1280px) {
  .container {
    max-width: 1280px;
  }
}

@media (width >= 1536px) {
  .container {
    max-width: 1536px;
  }
}

.bg-login-light-bubbles-1 {
  background: url("login_bg_light.51dac81f.svg") bottom / contain no-repeat;
}

.bg-login-light-bubbles-1:before {
  content: "";
  backdrop-filter: blur(11px);
  width: 120px;
  height: 120px;
  position: absolute;
  top: -44px;
  left: 56px;
}

.bg-login-light-bubbles-1:after {
  content: "";
  backdrop-filter: blur(42px);
  width: 170px;
  height: 170px;
  position: absolute;
  top: 86px;
  right: -44px;
}

.bg-login-light-bubbles-1:before, .bg-login-light-bubbles-1:after, .bg-login-light-bubbles-2:before {
  opacity: .5;
  background: linear-gradient(95.58deg, #cee8e7 27.33%, #9cc5db 100%);
  border-radius: 100%;
  transform: rotate(-180deg);
  box-shadow: -16px -41px 50px #00000026;
}

.bg-header {
  background: linear-gradient(95deg, #fff 0%, #cee8e7 15.1% 85.42%, #9cc5db 100%), linear-gradient(95deg, #fff 0%, #cee8e7 30.59%, #9cc5db 100%);
}

.bg-hero {
  background: url("hero_bg.9dabff2f.svg") 98% 32px no-repeat, linear-gradient(271.08deg, #235066 0%, #607985 100%);
}

.bg-card-plastic {
  background: #b6b2ab url("premium-plastic.9c6373d3.png") 0 0 / contain no-repeat;
  border-radius: 16px;
}

.bg-card-virtual {
  background: #d9dada url("premium-virtual.5bfe7729.png") 0 0 / contain no-repeat;
  border-radius: 16px;
}

.bg-card-szamlazz {
  background: url("premium-szamlazz.664c1624.png") 0 0 / contain no-repeat;
  border-radius: 16px;
}

.bg-menu-header {
  background: linear-gradient(95.32deg, #fff 0%, #cee8e7 51.66%, #9cc5db 100%);
}

.bg-footer {
  background: linear-gradient(271.08deg, #235066 0%, #607985 100%);
}

.partners-favorites-swiper-cards-gradient-edges {
  -webkit-mask-image: linear-gradient(to right, #0000 0%, #000 3% 97%, #0000 100%);
  mask-image: linear-gradient(to right, #0000 0%, #000 3% 97%, #0000 100%);
}

.add-new-partner-dropdown-curved-corner {
  z-index: -1;
  width: 100px;
  height: 100px;
  margin-top: -75.2px;
  margin-left: 143.8px;
  position: relative;
  overflow: hidden;
  transform: scale(.5);
}

.add-new-partner-dropdown-curved-corner:before {
  content: "";
  border-radius: 50%;
  width: 100px;
  height: 100px;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  box-shadow: 50px 50px #fff;
}

.icon-bg {
  background: linear-gradient(271.08deg, #235066 0%, #607985 100%);
}

.binx-partner-gradient {
  background: linear-gradient(270.31deg, #fff .24%, #fff0 99.73%), linear-gradient(95.58deg, #cee8e7 27.33%, #9cc5db 100%), #fff;
}

.header-account-popover-bg {
  background-color: #0000;
  background-image: url("header_popover_shape.84a35876.svg"), linear-gradient(270.31deg, #fff .24%, #fff0 99.73%), linear-gradient(95.58deg, #cee8e780 27.33%, #9cc5db80 100%);
  background-position: 0;
  background-repeat: no-repeat, repeat, repeat;
  background-size: auto, auto, auto;
  background-attachment: scroll, scroll, scroll;
  background-origin: padding-box, padding-box, padding-box;
  background-clip: border-box, border-box, border-box;
}

.card-trx-data-section-bg {
  background-color: #0000;
  background-image: url("header_popover_shape.84a35876.svg"), linear-gradient(77.47deg, #cfe1e5 9.09%, #fff 90.91%);
  background-position: 0;
  background-repeat: no-repeat, repeat;
  background-size: auto 100%;
  background-attachment: scroll, scroll;
  background-origin: padding-box, padding-box;
  background-clip: border-box, border-box;
}

.main {
  min-height: calc(100vh - 184px);
}

.bg-active-issue {
  background: linear-gradient(90deg, #e1eef400 0%, #cbe1ec 100%), linear-gradient(0deg, #f0f6fa, #f0f6fa);
}

.pointer-events-none {
  pointer-events: none;
}

.visible {
  visibility: visible;
}

.invisible {
  visibility: hidden;
}

.fixed {
  position: fixed;
}

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.sticky {
  position: sticky;
}

.-bottom-5 {
  bottom: -1.25rem;
}

.-right-1 {
  right: -.25rem;
}

.-right-2 {
  right: -.5rem;
}

.-right-2\.5 {
  right: -.625rem;
}

.-top-0 {
  top: 0;
}

.-top-2 {
  top: -.5rem;
}

.-top-3 {
  top: -.75rem;
}

.-top-3\.5 {
  top: -.875rem;
}

.-top-8 {
  top: -2rem;
}

.-top-\[110px\] {
  top: -110px;
}

.-top-\[48px\] {
  top: -48px;
}

.-top-\[6\.25rem\] {
  top: -6.25rem;
}

.bottom-0 {
  bottom: 0;
}

.bottom-4 {
  bottom: 1rem;
}

.bottom-6 {
  bottom: 1.5rem;
}

.left-0 {
  left: 0;
}

.left-1 {
  left: .25rem;
}

.left-3 {
  left: .75rem;
}

.left-4 {
  left: 1rem;
}

.left-5 {
  left: 1.25rem;
}

.right-0 {
  right: 0;
}

.right-3 {
  right: .75rem;
}

.right-4 {
  right: 1rem;
}

.right-5 {
  right: 1.25rem;
}

.top-0 {
  top: 0;
}

.top-1 {
  top: .25rem;
}

.top-1\/2 {
  top: 50%;
}

.top-2 {
  top: .5rem;
}

.top-3 {
  top: .75rem;
}

.top-\[18px\] {
  top: 18px;
}

.z-10 {
  z-index: 10;
}

.z-20 {
  z-index: 20;
}

.z-30 {
  z-index: 30;
}

.z-40 {
  z-index: 40;
}

.z-50 {
  z-index: 50;
}

.z-\[2\] {
  z-index: 2;
}

.z-\[60\] {
  z-index: 60;
}

.z-\[90\] {
  z-index: 90;
}

.z-\[9999\] {
  z-index: 9999;
}

.order-1 {
  order: 1;
}

.order-2 {
  order: 2;
}

.order-3 {
  order: 3;
}

.col-start-3 {
  grid-column-start: 3;
}

.col-start-4 {
  grid-column-start: 4;
}

.row-span-2 {
  grid-row: span 2 / span 2;
}

.row-start-1 {
  grid-row-start: 1;
}

.row-start-2 {
  grid-row-start: 2;
}

.m-0 {
  margin: 0;
}

.m-2 {
  margin: .5rem;
}

.m-4 {
  margin: 1rem;
}

.m-5 {
  margin: 1.25rem;
}

.m-auto {
  margin: auto;
}

.mx-2 {
  margin-left: .5rem;
  margin-right: .5rem;
}

.mx-6 {
  margin-left: 1.5rem;
  margin-right: 1.5rem;
}

.mx-\[-20px\] {
  margin-left: -20px;
  margin-right: -20px;
}

.mx-\[25\%\] {
  margin-left: 25%;
  margin-right: 25%;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.my-2 {
  margin-top: .5rem;
  margin-bottom: .5rem;
}

.my-3 {
  margin-top: .75rem;
  margin-bottom: .75rem;
}

.my-4 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.my-5 {
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
}

.my-6 {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

.-ml-7 {
  margin-left: -1.75rem;
}

.-ml-\[39px\] {
  margin-left: -39px;
}

.-mr-4 {
  margin-right: -1rem;
}

.-mr-\[39px\] {
  margin-right: -39px;
}

.-mt-12 {
  margin-top: -3rem;
}

.-mt-16 {
  margin-top: -4rem;
}

.-mt-4 {
  margin-top: -1rem;
}

.-mt-6 {
  margin-top: -1.5rem;
}

.-mt-\[0\.125rem\] {
  margin-top: -.125rem;
}

.mb-1 {
  margin-bottom: .25rem;
}

.mb-10 {
  margin-bottom: 2.5rem;
}

.mb-12 {
  margin-bottom: 3rem;
}

.mb-14 {
  margin-bottom: 3.5rem;
}

.mb-2 {
  margin-bottom: .5rem;
}

.mb-20 {
  margin-bottom: 5rem;
}

.mb-3 {
  margin-bottom: .75rem;
}

.mb-4 {
  margin-bottom: 1rem;
}

.mb-5 {
  margin-bottom: 1.25rem;
}

.mb-6 {
  margin-bottom: 1.5rem;
}

.mb-7 {
  margin-bottom: 1.75rem;
}

.mb-8 {
  margin-bottom: 2rem;
}

.mb-9 {
  margin-bottom: 2.25rem;
}

.ml-1 {
  margin-left: .25rem;
}

.ml-2 {
  margin-left: .5rem;
}

.ml-3 {
  margin-left: .75rem;
}

.ml-4 {
  margin-left: 1rem;
}

.ml-5 {
  margin-left: 1.25rem;
}

.ml-7 {
  margin-left: 1.75rem;
}

.ml-\[110px\] {
  margin-left: 110px;
}

.ml-auto {
  margin-left: auto;
}

.mr-2 {
  margin-right: .5rem;
}

.mr-3 {
  margin-right: .75rem;
}

.mr-4 {
  margin-right: 1rem;
}

.mr-auto {
  margin-right: auto;
}

.mt-0 {
  margin-top: 0;
}

.mt-0\.5 {
  margin-top: .125rem;
}

.mt-1 {
  margin-top: .25rem;
}

.mt-1\.5 {
  margin-top: .375rem;
}

.mt-10 {
  margin-top: 2.5rem;
}

.mt-12 {
  margin-top: 3rem;
}

.mt-2 {
  margin-top: .5rem;
}

.mt-20 {
  margin-top: 5rem;
}

.mt-28 {
  margin-top: 7rem;
}

.mt-3 {
  margin-top: .75rem;
}

.mt-4 {
  margin-top: 1rem;
}

.mt-5 {
  margin-top: 1.25rem;
}

.mt-6 {
  margin-top: 1.5rem;
}

.mt-7 {
  margin-top: 1.75rem;
}

.mt-8 {
  margin-top: 2rem;
}

.mt-9 {
  margin-top: 2.25rem;
}

.mt-\[30px\] {
  margin-top: 30px;
}

.mt-\[6px\] {
  margin-top: 6px;
}

.mt-auto {
  margin-top: auto;
}

.line-clamp-1 {
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
}

.line-clamp-2 {
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
}

.line-clamp-3 {
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
}

.\!block {
  display: block !important;
}

.block {
  display: block;
}

.flex {
  display: flex;
}

.inline-flex {
  display: inline-flex;
}

.table {
  display: table;
}

.table-cell {
  display: table-cell;
}

.grid {
  display: grid;
}

.contents {
  display: contents;
}

.hidden {
  display: none;
}

.aspect-\[0\.5625\] {
  aspect-ratio: .5625;
}

.aspect-square {
  aspect-ratio: 1;
}

.\!h-1 {
  height: .25rem !important;
}

.h-0 {
  height: 0;
}

.h-1 {
  height: .25rem;
}

.h-10 {
  height: 2.5rem;
}

.h-11 {
  height: 2.75rem;
}

.h-12 {
  height: 3rem;
}

.h-16 {
  height: 4rem;
}

.h-2 {
  height: .5rem;
}

.h-20 {
  height: 5rem;
}

.h-24 {
  height: 6rem;
}

.h-3 {
  height: .75rem;
}

.h-4 {
  height: 1rem;
}

.h-5 {
  height: 1.25rem;
}

.h-52 {
  height: 13rem;
}

.h-6 {
  height: 1.5rem;
}

.h-7 {
  height: 1.75rem;
}

.h-8 {
  height: 2rem;
}

.h-96 {
  height: 24rem;
}

.h-\[130px\] {
  height: 130px;
}

.h-\[13px\] {
  height: 13px;
}

.h-\[160px\] {
  height: 160px;
}

.h-\[188px\] {
  height: 188px;
}

.h-\[190px\] {
  height: 190px;
}

.h-\[1em\] {
  height: 1em;
}

.h-\[1px\] {
  height: 1px;
}

.h-\[200px\] {
  height: 200px;
}

.h-\[22\.5rem\] {
  height: 22.5rem;
}

.h-\[300px\] {
  height: 300px;
}

.h-\[360px\] {
  height: 360px;
}

.h-\[380px\] {
  height: 380px;
}

.h-\[39\.5px\] {
  height: 39.5px;
}

.h-\[440px\] {
  height: 440px;
}

.h-\[5\.5rem\] {
  height: 5.5rem;
}

.h-\[500px\] {
  height: 500px;
}

.h-\[50px\] {
  height: 50px;
}

.h-\[52px\] {
  height: 52px;
}

.h-\[550px\] {
  height: 550px;
}

.h-\[552px\] {
  height: 552px;
}

.h-\[560px\] {
  height: 560px;
}

.h-\[580px\] {
  height: 580px;
}

.h-\[60px\] {
  height: 60px;
}

.h-\[630px\] {
  height: 630px;
}

.h-\[65px\] {
  height: 65px;
}

.h-\[688px\] {
  height: 688px;
}

.h-\[6px\] {
  height: 6px;
}

.h-\[70\%\] {
  height: 70%;
}

.h-\[75px\] {
  height: 75px;
}

.h-\[76px\] {
  height: 76px;
}

.h-\[797px\] {
  height: 797px;
}

.h-\[88px\] {
  height: 88px;
}

.h-\[unset\] {
  height: unset;
}

.h-auto {
  height: auto;
}

.h-fit {
  height: fit-content;
}

.h-full {
  height: 100%;
}

.h-screen {
  height: 100vh;
}

.max-h-9 {
  max-height: 2.25rem;
}

.max-h-\[100dvh\] {
  max-height: 100dvh;
}

.max-h-\[18\.75rem\] {
  max-height: 18.75rem;
}

.max-h-\[180px\] {
  max-height: 180px;
}

.max-h-\[250px\] {
  max-height: 250px;
}

.max-h-\[352px\] {
  max-height: 352px;
}

.max-h-\[400px\] {
  max-height: 400px;
}

.max-h-\[440px\] {
  max-height: 440px;
}

.min-h-6 {
  min-height: 1.5rem;
}

.min-h-\[160px\] {
  min-height: 160px;
}

.min-h-\[180px\] {
  min-height: 180px;
}

.min-h-\[24px\] {
  min-height: 24px;
}

.min-h-\[400px\] {
  min-height: 400px;
}

.min-h-\[48px\] {
  min-height: 48px;
}

.min-h-\[60px\] {
  min-height: 60px;
}

.min-h-fit {
  min-height: fit-content;
}

.w-1\/2 {
  width: 50%;
}

.w-10 {
  width: 2.5rem;
}

.w-11 {
  width: 2.75rem;
}

.w-12 {
  width: 3rem;
}

.w-16 {
  width: 4rem;
}

.w-2 {
  width: .5rem;
}

.w-24 {
  width: 6rem;
}

.w-28 {
  width: 7rem;
}

.w-4 {
  width: 1rem;
}

.w-44 {
  width: 11rem;
}

.w-5 {
  width: 1.25rem;
}

.w-56 {
  width: 14rem;
}

.w-6 {
  width: 1.5rem;
}

.w-7 {
  width: 1.75rem;
}

.w-8 {
  width: 2rem;
}

.w-80 {
  width: 20rem;
}

.w-96 {
  width: 24rem;
}

.w-\[13px\] {
  width: 13px;
}

.w-\[143\.3px\] {
  width: 143.3px;
}

.w-\[150px\] {
  width: 150px;
}

.w-\[188px\] {
  width: 188px;
}

.w-\[1em\] {
  width: 1em;
}

.w-\[1px\] {
  width: 1px;
}

.w-\[200px\] {
  width: 200px;
}

.w-\[22\.5rem\] {
  width: 22.5rem;
}

.w-\[250px\] {
  width: 250px;
}

.w-\[280px\] {
  width: 280px;
}

.w-\[300px\] {
  width: 300px;
}

.w-\[302px\] {
  width: 302px;
}

.w-\[320px\] {
  width: 320px;
}

.w-\[350\], .w-\[350px\] {
  width: 350px;
}

.w-\[360px\] {
  width: 360px;
}

.w-\[400px\] {
  width: 400px;
}

.w-\[480px\] {
  width: 480px;
}

.w-\[5\.5rem\] {
  width: 5.5rem;
}

.w-\[60\%\] {
  width: 60%;
}

.w-\[60px\] {
  width: 60px;
}

.w-\[88px\] {
  width: 88px;
}

.w-auto {
  width: auto;
}

.w-fit {
  width: fit-content;
}

.w-full {
  width: 100%;
}

.w-px {
  width: 1px;
}

.w-screen {
  width: 100vw;
}

.min-w-\[200px\] {
  min-width: 200px;
}

.min-w-\[300px\] {
  min-width: 300px;
}

.min-w-\[320px\] {
  min-width: 320px;
}

.min-w-\[364px\] {
  min-width: 364px;
}

.min-w-\[56px\] {
  min-width: 56px;
}

.max-w-96 {
  max-width: 24rem;
}

.max-w-\[1190px\] {
  max-width: 1190px;
}

.max-w-\[12\.5rem\] {
  max-width: 12.5rem;
}

.max-w-\[31\.875rem\] {
  max-width: 31.875rem;
}

.max-w-\[350px\] {
  max-width: 350px;
}

.max-w-\[392px\] {
  max-width: 392px;
}

.max-w-\[40\%\] {
  max-width: 40%;
}

.max-w-\[450px\] {
  max-width: 450px;
}

.max-w-\[500px\] {
  max-width: 500px;
}

.max-w-\[510px\] {
  max-width: 510px;
}

.max-w-\[680px\] {
  max-width: 680px;
}

.max-w-\[700px\] {
  max-width: 700px;
}

.max-w-\[800px\] {
  max-width: 800px;
}

.max-w-content {
  max-width: 80rem;
}

.max-w-sm {
  max-width: 24rem;
}

.max-w-xs {
  max-width: 20rem;
}

.flex-1 {
  flex: 1;
}

.flex-none {
  flex: none;
}

.flex-shrink-0, .shrink-0 {
  flex-shrink: 0;
}

.basis-1\/2 {
  flex-basis: 50%;
}

.-translate-y-1\/2 {
  --tw-translate-y: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-x-\[14px\] {
  --tw-translate-x: 14px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-x-\[28px\] {
  --tw-translate-x: 28px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-y-0 {
  --tw-translate-y: 0px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-y-\[10px\] {
  --tw-translate-y: 10px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-rotate-180 {
  --tw-rotate: -180deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-rotate-90 {
  --tw-rotate: -90deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.rotate-180 {
  --tw-rotate: 180deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.rotate-45 {
  --tw-rotate: 45deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.rotate-90 {
  --tw-rotate: 90deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.scale-\[1\.15\] {
  --tw-scale-x: 1.15;
  --tw-scale-y: 1.15;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.transform {
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

@keyframes floating {
  0% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(-20%);
  }

  100% {
    transform: translateY(0);
  }
}

.animate-float {
  animation: 4s infinite floating;
}

@keyframes pulse {
  50% {
    opacity: .5;
  }
}

.animate-pulse {
  animation: 2s cubic-bezier(.4, 0, .6, 1) infinite pulse;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.animate-spin {
  animation: 1s linear infinite spin;
}

.cursor-default {
  cursor: default;
}

.cursor-not-allowed {
  cursor: not-allowed;
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-text {
  cursor: text;
}

.select-none {
  -webkit-user-select: none;
  user-select: none;
}

.resize-none {
  resize: none;
}

.list-inside {
  list-style-position: inside;
}

.list-outside {
  list-style-position: outside;
}

.list-disc {
  list-style-type: disc;
}

.list-none {
  list-style-type: none;
}

.appearance-none {
  appearance: none;
}

.grid-flow-row {
  grid-auto-flow: row;
}

.grid-flow-col {
  grid-auto-flow: column;
}

.grid-cols-1 {
  grid-template-columns: repeat(1, minmax(0, 1fr));
}

.grid-cols-2 {
  grid-template-columns: repeat(2, minmax(0, 1fr));
}

.grid-cols-7 {
  grid-template-columns: repeat(7, minmax(0, 1fr));
}

.grid-cols-\[1fr\,150px\] {
  grid-template-columns: 1fr 150px;
}

.grid-cols-\[1fr\,60px\] {
  grid-template-columns: 1fr 60px;
}

.grid-cols-\[1fr\,64px\] {
  grid-template-columns: 1fr 64px;
}

.grid-cols-\[1fr\,auto\] {
  grid-template-columns: 1fr auto;
}

.grid-cols-\[233px\,1fr\] {
  grid-template-columns: 233px 1fr;
}

.grid-cols-\[392px\,1fr\] {
  grid-template-columns: 392px 1fr;
}

.grid-cols-\[44px\,1fr\,44px\] {
  grid-template-columns: 44px 1fr 44px;
}

.grid-cols-\[48px\,1fr\,1fr\,1fr\] {
  grid-template-columns: 48px 1fr 1fr 1fr;
}

.grid-cols-\[48px\,1fr\,44px\] {
  grid-template-columns: 48px 1fr 44px;
}

.grid-cols-\[48px\,1fr\] {
  grid-template-columns: 48px 1fr;
}

.grid-cols-\[60px\,1fr\] {
  grid-template-columns: 60px 1fr;
}

.grid-cols-\[84px\,1fr\] {
  grid-template-columns: 84px 1fr;
}

.grid-cols-\[auto\,1fr\,auto\] {
  grid-template-columns: auto 1fr auto;
}

.grid-cols-\[auto\,1fr\] {
  grid-template-columns: auto 1fr;
}

.grid-cols-\[auto\,auto\] {
  grid-template-columns: auto auto;
}

.grid-rows-2 {
  grid-template-rows: repeat(2, minmax(0, 1fr));
}

.grid-rows-4 {
  grid-template-rows: repeat(4, minmax(0, 1fr));
}

.grid-rows-5 {
  grid-template-rows: repeat(5, minmax(0, 1fr));
}

.grid-rows-\[1fr\,1fr\,auto\] {
  grid-template-rows: 1fr 1fr auto;
}

.grid-rows-\[1fr\,auto\] {
  grid-template-rows: 1fr auto;
}

.grid-rows-\[auto\,1fr\] {
  grid-template-rows: auto 1fr;
}

.flex-row {
  flex-direction: row;
}

.flex-col {
  flex-direction: column;
}

.flex-col-reverse {
  flex-direction: column-reverse;
}

.flex-wrap {
  flex-wrap: wrap;
}

.content-center {
  align-content: center;
}

.\!items-start {
  align-items: flex-start !important;
}

.items-start {
  align-items: flex-start;
}

.items-end {
  align-items: flex-end;
}

.items-center {
  align-items: center;
}

.justify-start {
  justify-content: flex-start;
}

.justify-end {
  justify-content: flex-end;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.justify-around {
  justify-content: space-around;
}

.justify-items-start {
  justify-items: start;
}

.justify-items-end {
  justify-items: end;
}

.justify-items-center {
  justify-items: center;
}

.gap-0 {
  gap: 0;
}

.gap-1 {
  gap: .25rem;
}

.gap-1\.5 {
  gap: .375rem;
}

.gap-10 {
  gap: 2.5rem;
}

.gap-12 {
  gap: 3rem;
}

.gap-14 {
  gap: 3.5rem;
}

.gap-2 {
  gap: .5rem;
}

.gap-3 {
  gap: .75rem;
}

.gap-4 {
  gap: 1rem;
}

.gap-48 {
  gap: 12rem;
}

.gap-5 {
  gap: 1.25rem;
}

.gap-6 {
  gap: 1.5rem;
}

.gap-7 {
  gap: 1.75rem;
}

.gap-8 {
  gap: 2rem;
}

.gap-x-14 {
  column-gap: 3.5rem;
}

.gap-y-2 {
  row-gap: .5rem;
}

.space-y-5 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(1.25rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(1.25rem * var(--tw-space-y-reverse));
}

.divide-x > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-x-reverse: 0;
  border-right-width: calc(1px * var(--tw-divide-x-reverse));
  border-left-width: calc(1px * calc(1 - var(--tw-divide-x-reverse)));
}

.divide-y > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-y-reverse: 0;
  border-top-width: calc(1px * calc(1 - var(--tw-divide-y-reverse)));
  border-bottom-width: calc(1px * var(--tw-divide-y-reverse));
}

.divide-solid > :not([hidden]) ~ :not([hidden]) {
  border-style: solid;
}

.divide-gray-200 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgb(225 231 234 / var(--tw-divide-opacity));
}

.divide-gray-300 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgb(208 216 221 / var(--tw-divide-opacity));
}

.divide-gray-400 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgb(151 171 180 / var(--tw-divide-opacity));
}

.place-self-start {
  place-self: start;
}

.self-end {
  align-self: flex-end;
}

.self-center {
  align-self: center;
}

.justify-self-start {
  justify-self: start;
}

.justify-self-end {
  justify-self: end;
}

.overflow-auto {
  overflow: auto;
}

.overflow-hidden {
  overflow: hidden;
}

.overflow-x-auto {
  overflow-x: auto;
}

.overflow-y-auto {
  overflow-y: auto;
}

.overflow-x-hidden {
  overflow-x: hidden;
}

.overflow-y-scroll {
  overflow-y: scroll;
}

.scroll-smooth {
  scroll-behavior: smooth;
}

.truncate {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.whitespace-nowrap {
  white-space: nowrap;
}

.break-words {
  overflow-wrap: break-word;
}

.break-all {
  word-break: break-all;
}

.rounded {
  border-radius: .25rem;
}

.rounded-2xl {
  border-radius: 1rem;
}

.rounded-3xl {
  border-radius: 1.5rem;
}

.rounded-\[0\.5rem\] {
  border-radius: .5rem;
}

.rounded-\[1\.25rem\] {
  border-radius: 1.25rem;
}

.rounded-\[100px\] {
  border-radius: 100px;
}

.rounded-\[14px\] {
  border-radius: 14px;
}

.rounded-\[18px\] {
  border-radius: 18px;
}

.rounded-\[20px\] {
  border-radius: 20px;
}

.rounded-full {
  border-radius: 9999px;
}

.rounded-lg {
  border-radius: .5rem;
}

.rounded-none {
  border-radius: 0;
}

.rounded-xl {
  border-radius: .75rem;
}

.rounded-b-3xl {
  border-bottom-right-radius: 1.5rem;
  border-bottom-left-radius: 1.5rem;
}

.rounded-l-full {
  border-top-left-radius: 9999px;
  border-bottom-left-radius: 9999px;
}

.rounded-r-full {
  border-top-right-radius: 9999px;
  border-bottom-right-radius: 9999px;
}

.rounded-t-3xl {
  border-top-left-radius: 1.5rem;
  border-top-right-radius: 1.5rem;
}

.rounded-t-xl {
  border-top-left-radius: .75rem;
  border-top-right-radius: .75rem;
}

.rounded-bl-2xl {
  border-bottom-left-radius: 1rem;
}

.rounded-bl-3xl {
  border-bottom-left-radius: 1.5rem;
}

.rounded-bl-\[32px\] {
  border-bottom-left-radius: 32px;
}

.rounded-bl-none {
  border-bottom-left-radius: 0;
}

.rounded-br-2xl {
  border-bottom-right-radius: 1rem;
}

.rounded-br-4xl {
  border-bottom-right-radius: 2rem;
}

.rounded-br-\[0\.5rem\] {
  border-bottom-right-radius: .5rem;
}

.rounded-br-\[32px\] {
  border-bottom-right-radius: 32px;
}

.rounded-br-md {
  border-bottom-right-radius: .375rem;
}

.rounded-br-none {
  border-bottom-right-radius: 0;
}

.rounded-br-xl {
  border-bottom-right-radius: .75rem;
}

.rounded-tl-3xl {
  border-top-left-radius: 1.5rem;
}

.rounded-tl-\[16px\] {
  border-top-left-radius: 16px;
}

.rounded-tl-\[20px\] {
  border-top-left-radius: 20px;
}

.rounded-tl-none {
  border-top-left-radius: 0;
}

.rounded-tr-3xl {
  border-top-right-radius: 1.5rem;
}

.rounded-tr-\[0\.5rem\] {
  border-top-right-radius: .5rem;
}

.rounded-tr-\[16px\] {
  border-top-right-radius: 16px;
}

.rounded-tr-\[20px\] {
  border-top-right-radius: 20px;
}

.rounded-tr-none {
  border-top-right-radius: 0;
}

.rounded-tr-xl {
  border-top-right-radius: .75rem;
}

.border {
  border-width: 1px;
}

.border-0 {
  border-width: 0;
}

.border-2 {
  border-width: 2px;
}

.border-y {
  border-top-width: 1px;
  border-bottom-width: 1px;
}

.border-b {
  border-bottom-width: 1px;
}

.border-b-4 {
  border-bottom-width: 4px;
}

.border-l {
  border-left-width: 1px;
}

.border-r {
  border-right-width: 1px;
}

.border-t {
  border-top-width: 1px;
}

.border-t-4 {
  border-top-width: 4px;
}

.border-dashed {
  border-style: dashed;
}

.\!border-none {
  border-style: none !important;
}

.border-none {
  border-style: none;
}

.\!border-error-800 {
  --tw-border-opacity: 1 !important;
  border-color: rgb(178 6 80 / var(--tw-border-opacity)) !important;
}

.border-error-800 {
  --tw-border-opacity: 1;
  border-color: rgb(178 6 80 / var(--tw-border-opacity));
}

.border-gray-100 {
  --tw-border-opacity: 1;
  border-color: rgb(240 243 244 / var(--tw-border-opacity));
}

.border-gray-200 {
  --tw-border-opacity: 1;
  border-color: rgb(225 231 234 / var(--tw-border-opacity));
}

.border-gray-300 {
  --tw-border-opacity: 1;
  border-color: rgb(208 216 221 / var(--tw-border-opacity));
}

.border-gray-400 {
  --tw-border-opacity: 1;
  border-color: rgb(151 171 180 / var(--tw-border-opacity));
}

.border-gray-500 {
  --tw-border-opacity: 1;
  border-color: rgb(119 144 156 / var(--tw-border-opacity));
}

.border-gray-600 {
  --tw-border-opacity: 1;
  border-color: rgb(96 121 133 / var(--tw-border-opacity));
}

.border-gray-700 {
  --tw-border-opacity: 1;
  border-color: rgb(62 78 86 / var(--tw-border-opacity));
}

.border-gray-800 {
  --tw-border-opacity: 1;
  border-color: rgb(32 40 44 / var(--tw-border-opacity));
}

.border-gray-900 {
  --tw-border-opacity: 1;
  border-color: rgb(30 38 41 / var(--tw-border-opacity));
}

.border-primary-300 {
  --tw-border-opacity: 1;
  border-color: rgb(203 225 236 / var(--tw-border-opacity));
}

.border-primary-400 {
  --tw-border-opacity: 1;
  border-color: rgb(161 201 221 / var(--tw-border-opacity));
}

.border-primary-700 {
  --tw-border-opacity: 1;
  border-color: rgb(50 109 139 / var(--tw-border-opacity));
}

.border-secondary-700 {
  --tw-border-opacity: 1;
  border-color: rgb(46 127 125 / var(--tw-border-opacity));
}

.border-success-700 {
  --tw-border-opacity: 1;
  border-color: rgb(0 128 123 / var(--tw-border-opacity));
}

.border-white {
  --tw-border-opacity: 1;
  border-color: rgb(255 255 255 / var(--tw-border-opacity));
}

.border-b-gray-200 {
  --tw-border-opacity: 1;
  border-bottom-color: rgb(225 231 234 / var(--tw-border-opacity));
}

.border-t-\[\#B20650\] {
  --tw-border-opacity: 1;
  border-top-color: rgb(178 6 80 / var(--tw-border-opacity));
}

.\!bg-gray-100 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(240 243 244 / var(--tw-bg-opacity)) !important;
}

.\!bg-primary-700 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(50 109 139 / var(--tw-bg-opacity)) !important;
}

.bg-\[\#F4F7F7\] {
  --tw-bg-opacity: 1;
  background-color: rgb(244 247 247 / var(--tw-bg-opacity));
}

.bg-black {
  --tw-bg-opacity: 1;
  background-color: rgb(0 0 0 / var(--tw-bg-opacity));
}

.bg-black\/20 {
  background-color: #0003;
}

.bg-black\/40 {
  background-color: #0006;
}

.bg-black\/60 {
  background-color: #0009;
}

.bg-black\/\[0\.5\] {
  background-color: #00000080;
}

.bg-error-600\/40 {
  background-color: #f93e8f66;
}

.bg-error-800 {
  --tw-bg-opacity: 1;
  background-color: rgb(178 6 80 / var(--tw-bg-opacity));
}

.bg-error-900 {
  --tw-bg-opacity: 1;
  background-color: rgb(138 0 60 / var(--tw-bg-opacity));
}

.bg-gray-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(240 243 244 / var(--tw-bg-opacity));
}

.bg-gray-100\/80 {
  background-color: #f0f3f4cc;
}

.bg-gray-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(225 231 234 / var(--tw-bg-opacity));
}

.bg-gray-300 {
  --tw-bg-opacity: 1;
  background-color: rgb(208 216 221 / var(--tw-bg-opacity));
}

.bg-gray-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(249 250 251 / var(--tw-bg-opacity));
}

.bg-gray-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(96 121 133 / var(--tw-bg-opacity));
}

.bg-gray-700 {
  --tw-bg-opacity: 1;
  background-color: rgb(62 78 86 / var(--tw-bg-opacity));
}

.bg-gray-800 {
  --tw-bg-opacity: 1;
  background-color: rgb(32 40 44 / var(--tw-bg-opacity));
}

.bg-gray-900 {
  --tw-bg-opacity: 1;
  background-color: rgb(30 38 41 / var(--tw-bg-opacity));
}

.bg-primary-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(240 246 250 / var(--tw-bg-opacity));
}

.bg-primary-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(225 238 244 / var(--tw-bg-opacity));
}

.bg-primary-200\/80 {
  background-color: #e1eef4cc;
}

.bg-primary-300 {
  --tw-bg-opacity: 1;
  background-color: rgb(203 225 236 / var(--tw-bg-opacity));
}

.bg-primary-400\/40 {
  background-color: #a1c9dd66;
}

.bg-primary-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(248 251 252 / var(--tw-bg-opacity));
}

.bg-primary-900 {
  --tw-bg-opacity: 1;
  background-color: rgb(27 59 75 / var(--tw-bg-opacity));
}

.bg-secondary-700 {
  --tw-bg-opacity: 1;
  background-color: rgb(46 127 125 / var(--tw-bg-opacity));
}

.bg-secondary-900 {
  --tw-bg-opacity: 1;
  background-color: rgb(27 75 69 / var(--tw-bg-opacity));
}

.bg-success-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(0 204 197 / var(--tw-bg-opacity));
}

.bg-success-700 {
  --tw-bg-opacity: 1;
  background-color: rgb(0 128 123 / var(--tw-bg-opacity));
}

.bg-success-900 {
  --tw-bg-opacity: 1;
  background-color: rgb(0 41 39 / var(--tw-bg-opacity));
}

.bg-transparent {
  background-color: #0000;
}

.bg-white {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.bg-\[linear-gradient\(77\.47deg\,_\#CFE1E5_9\.09\%\,_\#FFFFFF_90\.91\%\)\] {
  background-image: linear-gradient(77.47deg, #cfe1e5 9.09%, #fff 90.91%);
}

.bg-gradient-to-l {
  background-image: linear-gradient(to left, var(--tw-gradient-stops));
}

.bg-gradient-to-r {
  background-image: linear-gradient(to right, var(--tw-gradient-stops));
}

.bg-none {
  background-image: none;
}

.\!from-\[\#3A4950\] {
  --tw-gradient-from: #3a4950 var(--tw-gradient-from-position) !important;
  --tw-gradient-to: #3a495000 var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.from-\[\#235066\] {
  --tw-gradient-from: #235066 var(--tw-gradient-from-position);
  --tw-gradient-to: #23506600 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-\[\#3A4950\] {
  --tw-gradient-from: #3a4950 var(--tw-gradient-from-position);
  --tw-gradient-to: #3a495000 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-\[\#607985\] {
  --tw-gradient-from: #607985 var(--tw-gradient-from-position);
  --tw-gradient-to: #60798500 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-\[\#CEE8E7\] {
  --tw-gradient-from: #cee8e7 var(--tw-gradient-from-position);
  --tw-gradient-to: #cee8e700 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-\[\#feeee8\] {
  --tw-gradient-from: #feeee8 var(--tw-gradient-from-position);
  --tw-gradient-to: #feeee800 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-gray-400 {
  --tw-gradient-from: #97abb4 var(--tw-gradient-from-position);
  --tw-gradient-to: #97abb400 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-white {
  --tw-gradient-from: #fff var(--tw-gradient-from-position);
  --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-50\% {
  --tw-gradient-from-position: 50%;
}

.via-gray-50 {
  --tw-gradient-to: #f9fafb00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #f9fafb var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-50\% {
  --tw-gradient-via-position: 50%;
}

.\!to-\[\#16313E\] {
  --tw-gradient-to: #16313e var(--tw-gradient-to-position) !important;
}

.to-\[\#16313E\] {
  --tw-gradient-to: #16313e var(--tw-gradient-to-position);
}

.to-\[\#235066\] {
  --tw-gradient-to: #235066 var(--tw-gradient-to-position);
}

.to-\[\#607985\] {
  --tw-gradient-to: #607985 var(--tw-gradient-to-position);
}

.to-\[\#9CC5DB\] {
  --tw-gradient-to: #9cc5db var(--tw-gradient-to-position);
}

.to-\[\#f7b5ab\] {
  --tw-gradient-to: #f7b5ab var(--tw-gradient-to-position);
}

.to-gray-300 {
  --tw-gradient-to: #d0d8dd var(--tw-gradient-to-position);
}

.to-gray-400 {
  --tw-gradient-to: #97abb4 var(--tw-gradient-to-position);
}

.to-white {
  --tw-gradient-to: #fff var(--tw-gradient-to-position);
}

.bg-fixed {
  background-attachment: fixed;
}

.p-0 {
  padding: 0;
}

.p-1 {
  padding: .25rem;
}

.p-1\.5 {
  padding: .375rem;
}

.p-2 {
  padding: .5rem;
}

.p-2\.5 {
  padding: .625rem;
}

.p-3 {
  padding: .75rem;
}

.p-4 {
  padding: 1rem;
}

.p-5 {
  padding: 1.25rem;
}

.p-6 {
  padding: 1.5rem;
}

.p-8 {
  padding: 2rem;
}

.p-\[2px\] {
  padding: 2px;
}

.p-\[3px\] {
  padding: 3px;
}

.px-0 {
  padding-left: 0;
  padding-right: 0;
}

.px-2 {
  padding-left: .5rem;
  padding-right: .5rem;
}

.px-2\.5 {
  padding-left: .625rem;
  padding-right: .625rem;
}

.px-3 {
  padding-left: .75rem;
  padding-right: .75rem;
}

.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.px-5 {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}

.px-6 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.px-7 {
  padding-left: 1.75rem;
  padding-right: 1.75rem;
}

.px-8 {
  padding-left: 2rem;
  padding-right: 2rem;
}

.px-\[0\.125rem\] {
  padding-left: .125rem;
  padding-right: .125rem;
}

.px-\[10px\] {
  padding-left: 10px;
  padding-right: 10px;
}

.py-0 {
  padding-top: 0;
  padding-bottom: 0;
}

.py-1 {
  padding-top: .25rem;
  padding-bottom: .25rem;
}

.py-12 {
  padding-top: 3rem;
  padding-bottom: 3rem;
}

.py-2 {
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.py-24 {
  padding-top: 6rem;
  padding-bottom: 6rem;
}

.py-3 {
  padding-top: .75rem;
  padding-bottom: .75rem;
}

.py-4 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.py-5 {
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
}

.py-6 {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

.py-8 {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.py-\[10px\] {
  padding-top: 10px;
  padding-bottom: 10px;
}

.py-\[2px\] {
  padding-top: 2px;
  padding-bottom: 2px;
}

.py-\[42px\] {
  padding-top: 42px;
  padding-bottom: 42px;
}

.pb-0 {
  padding-bottom: 0;
}

.pb-1 {
  padding-bottom: .25rem;
}

.pb-10 {
  padding-bottom: 2.5rem;
}

.pb-11 {
  padding-bottom: 2.75rem;
}

.pb-14 {
  padding-bottom: 3.5rem;
}

.pb-2 {
  padding-bottom: .5rem;
}

.pb-3 {
  padding-bottom: .75rem;
}

.pb-4 {
  padding-bottom: 1rem;
}

.pb-5 {
  padding-bottom: 1.25rem;
}

.pb-6 {
  padding-bottom: 1.5rem;
}

.pb-8 {
  padding-bottom: 2rem;
}

.pl-0 {
  padding-left: 0;
}

.pl-1 {
  padding-left: .25rem;
}

.pl-10 {
  padding-left: 2.5rem;
}

.pl-2 {
  padding-left: .5rem;
}

.pl-3 {
  padding-left: .75rem;
}

.pl-4 {
  padding-left: 1rem;
}

.pl-5 {
  padding-left: 1.25rem;
}

.pl-6 {
  padding-left: 1.5rem;
}

.pl-8 {
  padding-left: 2rem;
}

.pl-\[52px\] {
  padding-left: 52px;
}

.pl-\[62px\] {
  padding-left: 62px;
}

.pr-0 {
  padding-right: 0;
}

.pr-10 {
  padding-right: 2.5rem;
}

.pr-12 {
  padding-right: 3rem;
}

.pr-14 {
  padding-right: 3.5rem;
}

.pr-2 {
  padding-right: .5rem;
}

.pr-3 {
  padding-right: .75rem;
}

.pr-4 {
  padding-right: 1rem;
}

.pr-6 {
  padding-right: 1.5rem;
}

.pr-7 {
  padding-right: 1.75rem;
}

.pr-8 {
  padding-right: 2rem;
}

.pr-\[15px\] {
  padding-right: 15px;
}

.pt-0 {
  padding-top: 0;
}

.pt-1 {
  padding-top: .25rem;
}

.pt-10 {
  padding-top: 2.5rem;
}

.pt-12 {
  padding-top: 3rem;
}

.pt-2 {
  padding-top: .5rem;
}

.pt-20 {
  padding-top: 5rem;
}

.pt-3 {
  padding-top: .75rem;
}

.pt-4 {
  padding-top: 1rem;
}

.pt-5 {
  padding-top: 1.25rem;
}

.pt-6 {
  padding-top: 1.5rem;
}

.pt-7 {
  padding-top: 1.75rem;
}

.pt-9 {
  padding-top: 2.25rem;
}

.pt-\[3\.5rem\] {
  padding-top: 3.5rem;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-start {
  text-align: start;
}

.text-end {
  text-align: end;
}

.text-2xl {
  font-size: 1.5rem;
  line-height: 2rem;
}

.text-3xl {
  font-size: 1.875rem;
  line-height: 2.25rem;
}

.text-4xl {
  font-size: 2.25rem;
  line-height: 2.5rem;
}

.text-5xl {
  font-size: 3rem;
  line-height: 1;
}

.text-6xl {
  font-size: 3.75rem;
  line-height: 1;
}

.text-7xl {
  font-size: 4.5rem;
  line-height: 1;
}

.text-\[1\.375rem\] {
  font-size: 1.375rem;
}

.text-\[1\.5rem\] {
  font-size: 1.5rem;
}

.text-\[12px\] {
  font-size: 12px;
}

.text-\[15px\] {
  font-size: 15px;
}

.text-\[2\.5rem\] {
  font-size: 2.5rem;
}

.text-\[22px\] {
  font-size: 22px;
}

.text-\[3\.375rem\] {
  font-size: 3.375rem;
}

.text-\[44px\] {
  font-size: 44px;
}

.text-base {
  font-size: 1rem;
  line-height: 1.5rem;
}

.text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem;
}

.text-sm {
  font-size: .875rem;
  line-height: 1.25rem;
}

.text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.text-xs {
  font-size: .75rem;
  line-height: 1rem;
}

.\!font-semibold {
  font-weight: 600 !important;
}

.font-bold {
  font-weight: 700;
}

.font-light {
  font-weight: 300;
}

.font-medium {
  font-weight: 500;
}

.font-normal {
  font-weight: 400;
}

.font-semibold {
  font-weight: 600;
}

.uppercase {
  text-transform: uppercase;
}

.lowercase {
  text-transform: lowercase;
}

.tabular-nums {
  --tw-numeric-spacing: tabular-nums;
  font-variant-numeric: var(--tw-ordinal) var(--tw-slashed-zero) var(--tw-numeric-figure) var(--tw-numeric-spacing) var(--tw-numeric-fraction);
}

.leading-7 {
  line-height: 1.75rem;
}

.leading-\[24px\] {
  line-height: 24px;
}

.tracking-\[3px\] {
  letter-spacing: 3px;
}

.\!text-error-800 {
  --tw-text-opacity: 1 !important;
  color: rgb(178 6 80 / var(--tw-text-opacity)) !important;
}

.\!text-gray-900 {
  --tw-text-opacity: 1 !important;
  color: rgb(30 38 41 / var(--tw-text-opacity)) !important;
}

.text-error-400 {
  --tw-text-opacity: 1;
  color: rgb(252 166 203 / var(--tw-text-opacity));
}

.text-error-700 {
  --tw-text-opacity: 1;
  color: rgb(226 24 111 / var(--tw-text-opacity));
}

.text-error-800 {
  --tw-text-opacity: 1;
  color: rgb(178 6 80 / var(--tw-text-opacity));
}

.text-gray-200 {
  --tw-text-opacity: 1;
  color: rgb(225 231 234 / var(--tw-text-opacity));
}

.text-gray-300 {
  --tw-text-opacity: 1;
  color: rgb(208 216 221 / var(--tw-text-opacity));
}

.text-gray-400 {
  --tw-text-opacity: 1;
  color: rgb(151 171 180 / var(--tw-text-opacity));
}

.text-gray-500 {
  --tw-text-opacity: 1;
  color: rgb(119 144 156 / var(--tw-text-opacity));
}

.text-gray-600 {
  --tw-text-opacity: 1;
  color: rgb(96 121 133 / var(--tw-text-opacity));
}

.text-gray-700 {
  --tw-text-opacity: 1;
  color: rgb(62 78 86 / var(--tw-text-opacity));
}

.text-gray-800 {
  --tw-text-opacity: 1;
  color: rgb(32 40 44 / var(--tw-text-opacity));
}

.text-gray-900 {
  --tw-text-opacity: 1;
  color: rgb(30 38 41 / var(--tw-text-opacity));
}

.text-primary-400 {
  --tw-text-opacity: 1;
  color: rgb(161 201 221 / var(--tw-text-opacity));
}

.text-primary-600 {
  --tw-text-opacity: 1;
  color: rgb(68 147 187 / var(--tw-text-opacity));
}

.text-primary-700 {
  --tw-text-opacity: 1;
  color: rgb(50 109 139 / var(--tw-text-opacity));
}

.text-primary-800 {
  --tw-text-opacity: 1;
  color: rgb(41 88 112 / var(--tw-text-opacity));
}

.text-primary-900 {
  --tw-text-opacity: 1;
  color: rgb(27 59 75 / var(--tw-text-opacity));
}

.text-secondary-700 {
  --tw-text-opacity: 1;
  color: rgb(46 127 125 / var(--tw-text-opacity));
}

.text-secondary-800 {
  --tw-text-opacity: 1;
  color: rgb(41 112 110 / var(--tw-text-opacity));
}

.text-success-600 {
  --tw-text-opacity: 1;
  color: rgb(0 184 177 / var(--tw-text-opacity));
}

.text-success-700 {
  --tw-text-opacity: 1;
  color: rgb(0 128 123 / var(--tw-text-opacity));
}

.text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.underline {
  text-decoration-line: underline;
}

.underline-offset-2 {
  text-underline-offset: 2px;
}

.caret-transparent {
  caret-color: #0000;
}

.opacity-0 {
  opacity: 0;
}

.opacity-100 {
  opacity: 1;
}

.opacity-30 {
  opacity: .3;
}

.opacity-70 {
  opacity: .7;
}

.shadow {
  --tw-shadow: 0 1px 3px 0 #0000001a, 0 1px 2px -1px #0000001a;
  --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-2xl {
  --tw-shadow: 0 25px 50px -12px #00000040;
  --tw-shadow-colored: 0 25px 50px -12px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-\[-6px_-10px_15px_-3px_rgba\(0\,0\,0\,0\.15\)\] {
  --tw-shadow: -6px -10px 15px -3px #00000026;
  --tw-shadow-colored: -6px -10px 15px -3px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-\[6px_0_15px_0px_rgba\(0\,0\,0\,0\.1\)\] {
  --tw-shadow: 6px 0 15px 0px #0000001a;
  --tw-shadow-colored: 6px 0 15px 0px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-\[inset_0_0_0_rgb\(0\,0\,0\,0\)\] {
  --tw-shadow: inset 0 0 0 #0000;
  --tw-shadow-colored: inset 0 0 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-\[inset_0px_-8px_8px_rgb\(0\,0\,0\,0\.08\)\] {
  --tw-shadow: inset 0px -8px 8px #00000014;
  --tw-shadow-colored: inset 0px -8px 8px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-lg {
  --tw-shadow: 0 10px 15px -3px #0000001a, 0 4px 6px -4px #0000001a;
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-md {
  --tw-shadow: 0 4px 6px -1px #0000001a, 0 2px 4px -2px #0000001a;
  --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-none {
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-sm {
  --tw-shadow: 0 1px 2px 0 #0000000d;
  --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-xl {
  --tw-shadow: 0 20px 25px -5px #0000001a, 0 8px 10px -6px #0000001a;
  --tw-shadow-colored: 0 20px 25px -5px var(--tw-shadow-color), 0 8px 10px -6px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-gray-100 {
  --tw-shadow-color: #f0f3f4;
  --tw-shadow: var(--tw-shadow-colored);
}

.\!outline-none {
  outline-offset: 2px !important;
  outline: 2px solid #0000 !important;
}

.outline-none {
  outline-offset: 2px;
  outline: 2px solid #0000;
}

.outline {
  outline-style: solid;
}

.outline-0 {
  outline-width: 0;
}

.outline-1 {
  outline-width: 1px;
}

.outline-2 {
  outline-width: 2px;
}

.-outline-offset-2 {
  outline-offset: -2px;
}

.outline-offset-2 {
  outline-offset: 2px;
}

.outline-offset-4 {
  outline-offset: 4px;
}

.outline-error-800 {
  outline-color: #b20650;
}

.outline-gray-100 {
  outline-color: #f0f3f4;
}

.outline-gray-800 {
  outline-color: #20282c;
}

.outline-primary-300 {
  outline-color: #cbe1ec;
}

.outline-primary-700 {
  outline-color: #326d8b;
}

.outline-success-700 {
  outline-color: #00807b;
}

.ring {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.ring-1 {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.ring-4 {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(4px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.ring-gray-400 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(151 171 180 / var(--tw-ring-opacity));
}

.ring-gray-500 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(119 144 156 / var(--tw-ring-opacity));
}

.ring-gray-900 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(30 38 41 / var(--tw-ring-opacity));
}

.ring-primary-100 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(240 246 250 / var(--tw-ring-opacity));
}

.ring-secondary-700 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(46 127 125 / var(--tw-ring-opacity));
}

.ring-offset-0 {
  --tw-ring-offset-width: 0px;
}

.blur {
  --tw-blur: blur(8px);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.blur-sm {
  --tw-blur: blur(4px);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.filter {
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.transition {
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter, backdrop-filter;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.transition-all {
  transition-property: all;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.transition-opacity {
  transition-property: opacity;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.transition-transform {
  transition-property: transform;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.duration-0 {
  transition-duration: 0s;
}

.duration-100 {
  transition-duration: .1s;
}

.duration-200 {
  transition-duration: .2s;
}

.duration-300 {
  transition-duration: .3s;
}

.duration-500 {
  transition-duration: .5s;
}

.duration-75 {
  transition-duration: 75ms;
}

.ease-in {
  transition-timing-function: cubic-bezier(.4, 0, 1, 1);
}

.bg-inherit {
  background: inherit;
}

.\[text-shadow\:_0_0_0\.65px_\#1E2629\,_0_0_0\.65px_\#1E2629\] {
  text-shadow: 0 0 .65px #1e2629, 0 0 .65px #1e2629;
}

.\[text-shadow\:_0_0_0\.65px_\#20282C\,_0_0_0\.65px_\#20282C\] {
  text-shadow: 0 0 .65px #20282c, 0 0 .65px #20282c;
}

input:focus {
  outline: none;
}

:root {
  --swiper-pagination-bullet-inactive-color: #e1e7ea;
  --swiper-pagination-color: #607985;
  --swiper-pagination-bullet-inactive-opacity: 1;
  --swiper-pagination-bullet-horizontal-gap: 5px;
}

.swiper-pagination-bullet {
  transition: width .25s;
}

.swiper-pagination-bullet-active {
  border-radius: 4px !important;
  width: 28px !important;
}

@media not all and (width >= 1024px) {
  .max-lg\:bg-login-light-bubbles-2:before {
    opacity: .5;
    background: linear-gradient(95.58deg, #cee8e7 27.33%, #9cc5db 100%);
    border-radius: 100%;
    transform: rotate(-180deg);
    box-shadow: -16px -41px 50px #00000026;
  }

  .max-lg\:bg-login-light-bubbles-2 {
    background: url("mobile_login_bg_light.375cc3aa.svg") bottom / contain no-repeat;
  }

  .max-lg\:bg-login-light-bubbles-2:before {
    content: "";
    backdrop-filter: blur(42px);
    width: 120px;
    height: 120px;
    position: absolute;
    top: -66px;
    left: -71px;
  }
}

@media (width >= 1024px) {
  .lg\:bg-login-light-bubbles-1 {
    background: url("login_bg_light.51dac81f.svg") bottom / contain no-repeat;
  }

  .lg\:bg-login-light-bubbles-1:before {
    content: "";
    backdrop-filter: blur(11px);
    width: 120px;
    height: 120px;
    position: absolute;
    top: -44px;
    left: 56px;
  }

  .lg\:bg-login-light-bubbles-1:after {
    content: "";
    backdrop-filter: blur(42px);
    width: 170px;
    height: 170px;
    position: absolute;
    top: 86px;
    right: -44px;
  }

  .lg\:bg-login-light-bubbles-1:before, .lg\:bg-login-light-bubbles-1:after {
    opacity: .5;
    background: linear-gradient(95.58deg, #cee8e7 27.33%, #9cc5db 100%);
    border-radius: 100%;
    transform: rotate(-180deg);
    box-shadow: -16px -41px 50px #00000026;
  }

  .lg\:invoice-data-card-curved-corner-topleft, .lg\:invoice-data-card-curved-corner-topright {
    width: 40px;
    height: 40px;
    overflow: hidden;
  }

  .lg\:invoice-data-card-curved-corner-topleft:before, .lg\:invoice-data-card-curved-corner-topright:before {
    content: "";
    border-radius: 34px;
    width: 200%;
    height: 200%;
    display: block;
    position: absolute;
  }

  .lg\:invoice-data-card-curved-corner-topleft:before {
    top: 0;
    left: 0;
    box-shadow: -50px -50px #cee9e8;
  }

  .lg\:invoice-data-card-curved-corner-topright:before {
    top: 0;
    right: 0;
    box-shadow: 50px -50px #cee9e8;
  }
}

.\*\:m-0 > * {
  margin: 0;
}

.\*\:border-gray-300 > * {
  --tw-border-opacity: 1;
  border-color: rgb(208 216 221 / var(--tw-border-opacity));
}

.\*\:py-3 > * {
  padding-top: .75rem;
  padding-bottom: .75rem;
}

.\*\:pt-7 > * {
  padding-top: 1.75rem;
}

.\*\:text-sm > * {
  font-size: .875rem;
  line-height: 1.25rem;
}

.selection\:bg-transparent ::selection, .selection\:bg-transparent::selection {
  background-color: #0000;
}

.placeholder\:text-gray-500::placeholder {
  --tw-text-opacity: 1;
  color: rgb(119 144 156 / var(--tw-text-opacity));
}

.before\:absolute:before {
  content: var(--tw-content);
  position: absolute;
}

.before\:-left-full:before {
  content: var(--tw-content);
  left: -100%;
}

.before\:left-0:before {
  content: var(--tw-content);
  left: 0;
}

.before\:top-0:before {
  content: var(--tw-content);
  top: 0;
}

.before\:top-1\/2:before {
  content: var(--tw-content);
  top: 50%;
}

.before\:-mt-\[11px\]:before {
  content: var(--tw-content);
  margin-top: -11px;
}

.before\:block:before {
  content: var(--tw-content);
  display: block;
}

.before\:hidden:before {
  content: var(--tw-content);
  display: none;
}

.before\:h-1:before {
  content: var(--tw-content);
  height: .25rem;
}

.before\:h-16:before {
  content: var(--tw-content);
  height: 4rem;
}

.before\:h-\[22px\]:before {
  content: var(--tw-content);
  height: 22px;
}

.before\:w-16:before {
  content: var(--tw-content);
  width: 4rem;
}

.before\:w-\[1px\]:before {
  content: var(--tw-content);
  width: 1px;
}

.before\:w-full:before {
  content: var(--tw-content);
  width: 100%;
}

.before\:-translate-y-1\/2:before {
  content: var(--tw-content);
  --tw-translate-y: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.before\:bg-gray-200:before {
  content: var(--tw-content);
  --tw-bg-opacity: 1;
  background-color: rgb(225 231 234 / var(--tw-bg-opacity));
}

.before\:bg-\[url\(data\:image\/svg\+xml\;base64\,PHN2ZyB3aWR0aD0iMjMiIGhlaWdodD0iMjMiIHZpZXdCb3g9IjAgMCAyMyAyMyIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTAgMEgyM1YyM0MyMi40MzM2IDEwLjUzOTYgMTIuNDYwNCAwLjU2NjM4MiAwIDBaIiBmaWxsPSJ3aGl0ZSIvPgo8L3N2Zz4K\)\]:before {
  content: var(--tw-content);
  background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjMiIGhlaWdodD0iMjMiIHZpZXdCb3g9IjAgMCAyMyAyMyIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTAgMEgyM1YyM0MyMi40MzM2IDEwLjUzOTYgMTIuNDYwNCAwLjU2NjM4MiAwIDBaIiBmaWxsPSJ3aGl0ZSIvPgo8L3N2Zz4K");
}

.before\:bg-right-top:before {
  content: var(--tw-content);
  background-position: 100% 0;
}

.before\:bg-no-repeat:before {
  content: var(--tw-content);
  background-repeat: no-repeat;
}

.before\:content-\[\"\"\]:before {
  --tw-content: "";
  content: var(--tw-content);
}

.before\:content-\[attr\(x\)\]:before {
  --tw-content: attr(x);
  content: var(--tw-content);
}

.first\:\*\:pt-0 > :first-child {
  padding-top: 0;
}

.hover\:border-gray-500:hover {
  --tw-border-opacity: 1;
  border-color: rgb(119 144 156 / var(--tw-border-opacity));
}

.hover\:\!bg-transparent:hover {
  background-color: #0000 !important;
}

.hover\:bg-gray-50:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(249 250 251 / var(--tw-bg-opacity));
}

.hover\:bg-primary-200:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(225 238 244 / var(--tw-bg-opacity));
}

.hover\:bg-primary-50:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(248 251 252 / var(--tw-bg-opacity));
}

.hover\:bg-transparent:hover {
  background-color: #0000;
}

.hover\:bg-gradient-to-r:hover {
  background-image: linear-gradient(to right, var(--tw-gradient-stops));
}

.hover\:from-transparent:hover {
  --tw-gradient-from: transparent var(--tw-gradient-from-position);
  --tw-gradient-to: #0000 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.hover\:to-primary-300:hover {
  --tw-gradient-to: #cbe1ec var(--tw-gradient-to-position);
}

.hover\:text-primary-700:hover {
  --tw-text-opacity: 1;
  color: rgb(50 109 139 / var(--tw-text-opacity));
}

.hover\:text-primary-900:hover {
  --tw-text-opacity: 1;
  color: rgb(27 59 75 / var(--tw-text-opacity));
}

.hover\:underline:hover {
  text-decoration-line: underline;
}

.focus\:bg-primary-50:focus {
  --tw-bg-opacity: 1;
  background-color: rgb(248 251 252 / var(--tw-bg-opacity));
}

.focus\:bg-transparent:focus {
  background-color: #0000;
}

.focus\:text-primary-900:focus {
  --tw-text-opacity: 1;
  color: rgb(27 59 75 / var(--tw-text-opacity));
}

.focus\:outline-0:focus {
  outline-width: 0;
}

.focus\:ring-4:focus {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(4px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.focus\:ring-gray-500:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(119 144 156 / var(--tw-ring-opacity));
}

.focus\:ring-primary-100:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(240 246 250 / var(--tw-ring-opacity));
}

.focus\:ring-primary-300:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(203 225 236 / var(--tw-ring-opacity));
}

.focus\:ring-offset-0:focus {
  --tw-ring-offset-width: 0px;
}

.group:focus-within .group-focus-within\:ring {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.group:hover .group-hover\:block {
  display: block;
}

@media not all and (width >= 1024px) {
  .max-lg\:mt-5 {
    margin-top: 1.25rem;
  }

  .max-lg\:mt-6 {
    margin-top: 1.5rem;
  }

  .max-lg\:flex-col {
    flex-direction: column;
  }

  .max-lg\:justify-center {
    justify-content: center;
  }

  .max-lg\:gap-5 {
    gap: 1.25rem;
  }

  .max-lg\:gap-6 {
    gap: 1.5rem;
  }

  .max-lg\:pl-0 {
    padding-left: 0;
  }
}

@media not all and (width >= 768px) {
  .max-md\:m-0 {
    margin: 0;
  }

  .max-md\:mx-auto {
    margin-left: auto;
    margin-right: auto;
  }

  .max-md\:mb-6 {
    margin-bottom: 1.5rem;
  }

  .max-md\:mt-4 {
    margin-top: 1rem;
  }

  .max-md\:mt-5 {
    margin-top: 1.25rem;
  }

  .max-md\:flex {
    display: flex;
  }

  .max-md\:hidden {
    display: none;
  }

  .max-md\:max-h-\[200px\] {
    max-height: 200px;
  }

  .max-md\:w-fit {
    width: fit-content;
  }

  .max-md\:w-full {
    width: 100%;
  }

  .max-md\:max-w-\[4\.25rem\] {
    max-width: 4.25rem;
  }

  .max-md\:flex-row-reverse {
    flex-direction: row-reverse;
  }

  .max-md\:flex-col {
    flex-direction: column;
  }

  .max-md\:flex-col-reverse {
    flex-direction: column-reverse;
  }

  .max-md\:gap-2 {
    gap: .5rem;
  }

  .max-md\:gap-4 {
    gap: 1rem;
  }

  .max-md\:gap-5 {
    gap: 1.25rem;
  }

  .max-md\:gap-6 {
    gap: 1.5rem;
  }

  .max-md\:truncate {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  .max-md\:rounded-\[1\.25rem\] {
    border-radius: 1.25rem;
  }

  .max-md\:bg-transparent {
    background-color: #0000;
  }

  .max-md\:bg-white {
    --tw-bg-opacity: 1;
    background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  }

  .max-md\:p-0 {
    padding: 0;
  }

  .max-md\:py-6 {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }

  .max-md\:pb-7 {
    padding-bottom: 1.75rem;
  }

  .max-md\:text-center {
    text-align: center;
  }

  .max-md\:text-base {
    font-size: 1rem;
    line-height: 1.5rem;
  }

  .max-md\:shadow-none {
    --tw-shadow: 0 0 #0000;
    --tw-shadow-colored: 0 0 #0000;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }
}

@media not all and (width >= 640px) {
  .max-sm\:\!left-4 {
    left: 1rem !important;
  }

  .max-sm\:\!right-4 {
    right: 1rem !important;
  }

  .max-sm\:bg-transparent {
    background-color: #0000;
  }

  .max-sm\:shadow-none {
    --tw-shadow: 0 0 #0000;
    --tw-shadow-colored: 0 0 #0000;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .max-sm\:before\:hidden:before {
    content: var(--tw-content);
    display: none;
  }
}

@media (width >= 640px) {
  .sm\:m-0 {
    margin: 0;
  }

  .sm\:mb-1 {
    margin-bottom: .25rem;
  }

  .sm\:flex {
    display: flex;
  }

  .sm\:w-\[21\.875rem\] {
    width: 21.875rem;
  }

  .sm\:w-\[608px\] {
    width: 608px;
  }

  .sm\:flex-row {
    flex-direction: row;
  }

  .sm\:items-center {
    align-items: center;
  }

  .sm\:divide-x > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-x-reverse: 0;
    border-right-width: calc(1px * var(--tw-divide-x-reverse));
    border-left-width: calc(1px * calc(1 - var(--tw-divide-x-reverse)));
  }

  .sm\:bg-gray-200 {
    --tw-bg-opacity: 1;
    background-color: rgb(225 231 234 / var(--tw-bg-opacity));
  }

  .sm\:pl-2 {
    padding-left: .5rem;
  }

  .sm\:pr-2 {
    padding-right: .5rem;
  }
}

@media (width >= 768px) {
  .md\:absolute {
    position: absolute;
  }

  .md\:col-span-3 {
    grid-column: span 3 / span 3;
  }

  .md\:mx-0 {
    margin-left: 0;
    margin-right: 0;
  }

  .md\:mx-4 {
    margin-left: 1rem;
    margin-right: 1rem;
  }

  .md\:-ml-12 {
    margin-left: -3rem;
  }

  .md\:-mt-2 {
    margin-top: -.5rem;
  }

  .md\:mr-12 {
    margin-right: 3rem;
  }

  .md\:mt-0 {
    margin-top: 0;
  }

  .md\:mt-2 {
    margin-top: .5rem;
  }

  .md\:mt-24 {
    margin-top: 6rem;
  }

  .md\:mt-5 {
    margin-top: 1.25rem;
  }

  .md\:mt-8 {
    margin-top: 2rem;
  }

  .md\:block {
    display: block;
  }

  .md\:flex {
    display: flex;
  }

  .md\:grid {
    display: grid;
  }

  .md\:contents {
    display: contents;
  }

  .md\:hidden {
    display: none;
  }

  .md\:h-\[200px\] {
    height: 200px;
  }

  .md\:h-\[500px\] {
    height: 500px;
  }

  .md\:w-\[180px\] {
    width: 180px;
  }

  .md\:w-\[22\.875rem\] {
    width: 22.875rem;
  }

  .md\:w-\[320px\] {
    width: 320px;
  }

  .md\:w-\[7\.938rem\] {
    width: 7.938rem;
  }

  .md\:w-auto {
    width: auto;
  }

  .md\:w-fit {
    width: fit-content;
  }

  .md\:max-w-\[180px\] {
    max-width: 180px;
  }

  .md\:max-w-\[21\.875rem\] {
    max-width: 21.875rem;
  }

  .md\:max-w-\[680px\] {
    max-width: 680px;
  }

  .md\:grid-flow-col {
    grid-auto-flow: column;
  }

  .md\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .md\:grid-cols-\[1fr\,12\.5rem\] {
    grid-template-columns: 1fr 12.5rem;
  }

  .md\:grid-cols-\[1fr\,28px\] {
    grid-template-columns: 1fr 28px;
  }

  .md\:grid-cols-\[1fr\,auto\] {
    grid-template-columns: 1fr auto;
  }

  .md\:grid-cols-\[320px\,1fr\] {
    grid-template-columns: 320px 1fr;
  }

  .md\:grid-cols-\[auto\,1fr\,auto\] {
    grid-template-columns: auto 1fr auto;
  }

  .md\:grid-cols-\[auto\,1fr\] {
    grid-template-columns: auto 1fr;
  }

  .md\:flex-row {
    flex-direction: row;
  }

  .md\:flex-col {
    flex-direction: column;
  }

  .md\:items-center {
    align-items: center;
  }

  .md\:justify-end {
    justify-content: flex-end;
  }

  .md\:justify-between {
    justify-content: space-between;
  }

  .md\:gap-0 {
    gap: 0;
  }

  .md\:gap-28 {
    gap: 7rem;
  }

  .md\:gap-4 {
    gap: 1rem;
  }

  .md\:gap-5 {
    gap: 1.25rem;
  }

  .md\:divide-x > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-x-reverse: 0;
    border-right-width: calc(1px * var(--tw-divide-x-reverse));
    border-left-width: calc(1px * calc(1 - var(--tw-divide-x-reverse)));
  }

  .md\:divide-gray-200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgb(225 231 234 / var(--tw-divide-opacity));
  }

  .md\:justify-self-end {
    justify-self: end;
  }

  .md\:rounded-\[1\.25rem\] {
    border-radius: 1.25rem;
  }

  .md\:border-white {
    --tw-border-opacity: 1;
    border-color: rgb(255 255 255 / var(--tw-border-opacity));
  }

  .md\:bg-gray-100 {
    --tw-bg-opacity: 1;
    background-color: rgb(240 243 244 / var(--tw-bg-opacity));
  }

  .md\:bg-gray-200 {
    --tw-bg-opacity: 1;
    background-color: rgb(225 231 234 / var(--tw-bg-opacity));
  }

  .md\:bg-white {
    --tw-bg-opacity: 1;
    background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  }

  .md\:p-4 {
    padding: 1rem;
  }

  .md\:p-5 {
    padding: 1.25rem;
  }

  .md\:p-8 {
    padding: 2rem;
  }

  .md\:py-16 {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }

  .md\:py-8 {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }

  .md\:pb-16 {
    padding-bottom: 4rem;
  }

  .md\:pl-6 {
    padding-left: 1.5rem;
  }

  .md\:pr-6 {
    padding-right: 1.5rem;
  }

  .md\:pt-12 {
    padding-top: 3rem;
  }

  .md\:pt-8 {
    padding-top: 2rem;
  }

  .md\:text-3xl {
    font-size: 1.875rem;
    line-height: 2.25rem;
  }

  .md\:text-xl {
    font-size: 1.25rem;
    line-height: 1.75rem;
  }

  .md\:font-semibold {
    font-weight: 600;
  }

  .md\:text-white {
    --tw-text-opacity: 1;
    color: rgb(255 255 255 / var(--tw-text-opacity));
  }

  .md\:shadow-lg {
    --tw-shadow: 0 10px 15px -3px #0000001a, 0 4px 6px -4px #0000001a;
    --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .md\:shadow-none {
    --tw-shadow: 0 0 #0000;
    --tw-shadow-colored: 0 0 #0000;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }
}

@media (width >= 1024px) {
  .lg\:static {
    position: static;
  }

  .lg\:absolute {
    position: absolute;
  }

  .lg\:relative {
    position: relative;
  }

  .lg\:bottom-0 {
    bottom: 0;
  }

  .lg\:right-0 {
    right: 0;
  }

  .lg\:-order-1 {
    order: -1;
  }

  .lg\:order-4 {
    order: 4;
  }

  .lg\:col-span-2 {
    grid-column: span 2 / span 2;
  }

  .lg\:col-start-auto {
    grid-column-start: auto;
  }

  .lg\:row-span-1 {
    grid-row: span 1 / span 1;
  }

  .lg\:row-start-auto {
    grid-row-start: auto;
  }

  .lg\:ml-0 {
    margin-left: 0;
  }

  .lg\:ml-16 {
    margin-left: 4rem;
  }

  .lg\:mt-0 {
    margin-top: 0;
  }

  .lg\:mt-2 {
    margin-top: .5rem;
  }

  .lg\:block {
    display: block;
  }

  .lg\:flex {
    display: flex;
  }

  .lg\:grid {
    display: grid;
  }

  .lg\:hidden {
    display: none;
  }

  .lg\:h-\[22\.5rem\] {
    height: 22.5rem;
  }

  .lg\:h-\[430px\] {
    height: 430px;
  }

  .lg\:h-\[550px\] {
    height: 550px;
  }

  .lg\:h-\[72px\] {
    height: 72px;
  }

  .lg\:h-\[88px\] {
    height: 88px;
  }

  .lg\:w-\[310px\] {
    width: 310px;
  }

  .lg\:w-\[320px\] {
    width: 320px;
  }

  .lg\:w-auto {
    width: auto;
  }

  .lg\:w-fit {
    width: fit-content;
  }

  .lg\:w-full {
    width: 100%;
  }

  .lg\:max-w-\[220px\] {
    max-width: 220px;
  }

  .lg\:max-w-\[936px\] {
    max-width: 936px;
  }

  .lg\:grid-flow-row {
    grid-auto-flow: row;
  }

  .lg\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .lg\:grid-cols-\[230px\,230px\,auto\] {
    grid-template-columns: 230px 230px auto;
  }

  .lg\:grid-cols-\[360px\,auto\] {
    grid-template-columns: 360px auto;
  }

  .lg\:grid-cols-\[368px\,1fr\] {
    grid-template-columns: 368px 1fr;
  }

  .lg\:grid-cols-\[auto\,auto\] {
    grid-template-columns: auto auto;
  }

  .lg\:grid-rows-1 {
    grid-template-rows: repeat(1, minmax(0, 1fr));
  }

  .lg\:flex-row {
    flex-direction: row;
  }

  .lg\:flex-row-reverse {
    flex-direction: row-reverse;
  }

  .lg\:items-center {
    align-items: center;
  }

  .lg\:justify-end {
    justify-content: flex-end;
  }

  .lg\:justify-between {
    justify-content: space-between;
  }

  .lg\:gap-4 {
    gap: 1rem;
  }

  .lg\:gap-6 {
    gap: 1.5rem;
  }

  .lg\:gap-7 {
    gap: 1.75rem;
  }

  .lg\:divide-x > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-x-reverse: 0;
    border-right-width: calc(1px * var(--tw-divide-x-reverse));
    border-left-width: calc(1px * calc(1 - var(--tw-divide-x-reverse)));
  }

  .lg\:divide-x-2 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-x-reverse: 0;
    border-right-width: calc(2px * var(--tw-divide-x-reverse));
    border-left-width: calc(2px * calc(1 - var(--tw-divide-x-reverse)));
  }

  .lg\:divide-gray-200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgb(225 231 234 / var(--tw-divide-opacity));
  }

  .lg\:divide-primary-600 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgb(68 147 187 / var(--tw-divide-opacity));
  }

  .lg\:overflow-y-auto {
    overflow-y: auto;
  }

  .lg\:rounded-\[0\.5rem\] {
    border-radius: .5rem;
  }

  .lg\:rounded-l-3xl {
    border-top-left-radius: 1.5rem;
    border-bottom-left-radius: 1.5rem;
  }

  .lg\:rounded-bl-\[1\.25rem\] {
    border-bottom-left-radius: 1.25rem;
  }

  .lg\:rounded-br-\[1\.25rem\] {
    border-bottom-right-radius: 1.25rem;
  }

  .lg\:rounded-tl-none {
    border-top-left-radius: 0;
  }

  .lg\:rounded-tr-none {
    border-top-right-radius: 0;
  }

  .lg\:bg-secondary-300 {
    --tw-bg-opacity: 1;
    background-color: rgb(206 233 232 / var(--tw-bg-opacity));
  }

  .lg\:bg-white {
    --tw-bg-opacity: 1;
    background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  }

  .lg\:p-1 {
    padding: .25rem;
  }

  .lg\:px-5 {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }

  .lg\:px-8 {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .lg\:py-0 {
    padding-top: 0;
    padding-bottom: 0;
  }

  .lg\:pb-0 {
    padding-bottom: 0;
  }

  .lg\:pl-4 {
    padding-left: 1rem;
  }

  .lg\:pl-6 {
    padding-left: 1.5rem;
  }

  .lg\:pl-8 {
    padding-left: 2rem;
  }

  .lg\:pr-0 {
    padding-right: 0;
  }

  .lg\:pr-8 {
    padding-right: 2rem;
  }

  .lg\:pt-0 {
    padding-top: 0;
  }

  .lg\:pt-6 {
    padding-top: 1.5rem;
  }

  .lg\:text-left {
    text-align: left;
  }

  .lg\:text-center {
    text-align: center;
  }

  .lg\:text-3xl {
    font-size: 1.875rem;
    line-height: 2.25rem;
  }

  .lg\:text-\[150px\] {
    font-size: 150px;
  }

  .lg\:text-base {
    font-size: 1rem;
    line-height: 1.5rem;
  }

  .lg\:text-lg {
    font-size: 1.125rem;
    line-height: 1.75rem;
  }

  .lg\:text-xl {
    font-size: 1.25rem;
    line-height: 1.75rem;
  }

  .lg\:text-gray-900 {
    --tw-text-opacity: 1;
    color: rgb(30 38 41 / var(--tw-text-opacity));
  }

  .lg\:shadow-\[6px_0_15px_0px_rgba\(0\,0\,0\,0\.1\)\] {
    --tw-shadow: 6px 0 15px 0px #0000001a;
    --tw-shadow-colored: 6px 0 15px 0px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }
}

@media (width >= 1280px) {
  .xl\:flex {
    display: flex;
  }

  .xl\:w-\[380px\] {
    width: 380px;
  }

  .xl\:w-full {
    width: 100%;
  }

  .xl\:max-w-\[1192px\] {
    max-width: 1192px;
  }

  .xl\:gap-10 {
    gap: 2.5rem;
  }

  .xl\:gap-7 {
    gap: 1.75rem;
  }
}

@media (width >= 1536px) {
  .\32 xl\:max-w-\[1224px\] {
    max-width: 1224px;
  }
}

.\[\&\>button\>div\>div\]\:\!border-error-800 > button > div > div {
  --tw-border-opacity: 1 !important;
  border-color: rgb(178 6 80 / var(--tw-border-opacity)) !important;
}

.\[\&\>div\>div\:nth-child\(2\)\]\:hidden > div > div:nth-child(2), .\[\&\>div\>div\>button\]\:hidden > div > div > button {
  display: none;
}

.hover\:\[\&\>div\>div_\*\]\:cursor-pointer > div > div :hover {
  cursor: pointer;
}

.\[\&\>div\]\:relative > div {
  position: relative;
}

.\[\&\>div\]\:mt-0 > div {
  margin-top: 0;
}

.\[\&\>div\]\:\!font-normal > div {
  font-weight: 400 !important;
}

.\[\&\>h3\]\:font-semibold > h3 {
  font-weight: 600;
}

.\[\&\>h3\]\:text-error-800 > h3 {
  --tw-text-opacity: 1;
  color: rgb(178 6 80 / var(--tw-text-opacity));
}

.\[\&\>span\]\:m-0 > span {
  margin: 0;
}
/*# sourceMappingURL=index.f367a9bc.css.map */
